import React, { useContext } from 'react';
import { GlobalContext } from '../global-context';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { makeStyles, Container, Grid, Typography, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import Divide from '../reusable/Divide';
import ImageCarousel from '../reusable/ImageCarousel';

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';

import { useVehiclePhotos } from '../hooks/useVehiclePhotos';

import Loading from '../utils/Loading';
import ConciergeStepper from './conciergeDetails/ConciergeStepper';
import ConciergeDetailsInfo from './conciergeDetails/ConciergeDetailsInfo';
import ConciergeDetailsLocation from './conciergeDetails/ConciergeDetailsLocation';
import ConciergeDetailsTracking from './conciergeDetails/ConciergeDetailsTracking';

const log = false;

////////// COMPONENT //////////
export default function ConciergeDetails(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { getCarouselImagesArray } = useVehiclePhotos();

  const [imageSelection, setImageSelection] = React.useState(`pickup`);

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography className={cls.headL}>Track Your Vehicle</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography className={cls.headR}>
                If you have any issues, please call{' '}
                <a className={cls.phone} href={`tel:18009137674`}>
                  (800) 913-7674
                </a>
              </Typography>
            </Grid>
          </Grid>

          <div style={{ width: '100%', height: '24px' }} />

          <Subscription
            subscription={GET_CONCIERGE_MOVE}
            onError={err =>
              ctx.handleNotifications(true, `error`, `Failed to retrieve concierge move: ` + err.toString())
            }
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading fixed />;
              if (error) {
                console.error(`Failed to retrieve concierge move:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING CONCIERGE RECORD</Typography>
                  </div>
                );
              }
              const concierge =
                data && data.moves && data.moves.length > 0
                  ? data.moves.find(move => move.consumer_type === 'customer')
                  : null;
              if (concierge) {
                const childDriveMove =
                  Array.isArray(concierge.childMoves) &&
                  concierge.childMoves.length &&
                  concierge.childMoves[0].move_type === 'drive'
                    ? concierge.childMoves[0]
                    : null;
                const loaner =
                  childDriveMove && childDriveMove.consumer_type === 'loaner'
                    ? childDriveMove
                    : concierge.parentMove && concierge.parentMove.consumer_type === 'loaner'
                    ? concierge.parentMove
                    : null;
                const lyft =
                  concierge.lyftride && concierge.lyftride.activeAttempt ? concierge.lyftride.activeAttempt : null;
                log && console.log(`Concierge Details:`, concierge);
                log && console.log(`Loaner Details:`, loaner);
                log && console.log(`Lyft Details:`, lyft);

                const loanerPickupName = getPropValue(loaner, `lane.pickup.name`);
                const loanerDeliveryName = getPropValue(loaner, `lane.delivery.name`);
                let loanerPickupPhotos;
                let loanerDeliveryPhotos;

                const conciergePickupName = getPropValue(concierge, `lane.pickup.name`);
                const conciergeDeliveryName = getPropValue(concierge, `lane.delivery.name`);
                let conciergePickupPhotos;
                let conciergeDeliveryPhotos;

                let mainImageUrl;

                if (lyft && lyft.driver_vehicle_img_url) {
                  mainImageUrl = lyft.driver_vehicle_img_url;
                }

                if (loaner && loaner.pickup_photos && loaner.pickup_photos.length) {
                  const pickupSignerName =
                    loaner && loaner.pickup_workflow_data && loaner.pickup_workflow_data['signer-name'];
                  loanerPickupPhotos = getCarouselImagesArray({
                    move: loaner,
                    type: `pickup`,
                    signer: pickupSignerName,
                  });
                  if (loanerPickupPhotos.find(photo => photo.id === 'driver-front')) {
                    mainImageUrl = loanerPickupPhotos.find(photo => photo.id === 'driver-front').original;
                  } else mainImageUrl = null;
                }
                if (loaner && loaner.delivery_photos && loaner.delivery_photos.length) {
                  const deliverySignerName =
                    loaner && loaner.delivery_workflow_data && loaner.delivery_workflow_data['signer-name'];
                  loanerDeliveryPhotos = getCarouselImagesArray({
                    move: loaner,
                    type: `delivery`,
                    signer: deliverySignerName,
                  });
                }

                if (concierge && concierge.pickup_photos && concierge.pickup_photos.length) {
                  const pickupSignerName =
                    concierge && concierge.pickup_workflow_data && concierge.pickup_workflow_data['signer-name'];
                  conciergePickupPhotos = getCarouselImagesArray({
                    move: concierge,
                    type: `pickup`,
                    signer: pickupSignerName,
                  });
                  if (conciergePickupPhotos.find(photo => photo.id === 'driver-front')) {
                    mainImageUrl = conciergePickupPhotos.find(photo => photo.id === 'driver-front').original;
                  } else mainImageUrl = null;
                }
                if (concierge && concierge.delivery_photos && concierge.delivery_photos.length) {
                  const deliverySignerName =
                    concierge && concierge.delivery_workflow_data && concierge.delivery_workflow_data['signer-name'];
                  conciergeDeliveryPhotos = getCarouselImagesArray({
                    move: concierge,
                    type: `delivery`,
                    signer: deliverySignerName,
                  });
                }

                return (
                  <>
                    {/* MOVE STEPPER */}

                    <ConciergeStepper concierge={concierge} loaner={loaner} />

                    {/* MOVE INFO */}

                    <ConciergeDetailsInfo
                      concierge={concierge}
                      loaner={loaner}
                      lyft={lyft}
                      mainImageUrl={mainImageUrl}
                    />

                    {/* LANE/LOCATION INFO */}

                    <Divide
                      spacer
                      tip='See information on where your vehicle is being picked up from as well as where it is being delivered'
                    >
                      Location Details
                    </Divide>

                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <ConciergeDetailsLocation
                          title='Your Location'
                          type='pickup'
                          concierge={concierge}
                          loaner={loaner}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <ConciergeDetailsLocation
                          title='Service Location'
                          type='delivery'
                          concierge={concierge}
                          loaner={loaner}
                          hide={getPropValue(concierge, `config.hide_service_location`) || false}
                        />
                      </Grid>
                    </Grid>

                    {/* TRACKING INFO */}

                    {loaner &&
                    loaner.status &&
                    (loaner.status.includes(`pickup`) || loaner.status.includes(`delivery`)) ? (
                      <>
                        <Divide spacer tip='Track where our driver & loaner vehicle are currently located'>
                          Tracking Map
                        </Divide>

                        <ConciergeDetailsTracking move={loaner} />
                      </>
                    ) : null}

                    {concierge &&
                    concierge.status &&
                    (concierge.status.includes(`pickup`) || concierge.status.includes(`delivery`)) ? (
                      <>
                        <Divide spacer tip='Track where our driver & your vehicle are currently located'>
                          Tracking Map
                        </Divide>

                        <ConciergeDetailsTracking move={concierge} />
                      </>
                    ) : null}

                    {/* IMAGES */}

                    {(loanerPickupPhotos && loanerPickupPhotos.length) ||
                    (loanerDeliveryPhotos && loanerDeliveryPhotos.length) ? (
                      <>
                        <Divide spacer tip={`View your loaner's images from the pickup and delivery.`}>
                          Loaner Images
                        </Divide>
                        <div className={cls.paper}>
                          <TextField
                            select
                            fullWidth
                            size='small'
                            variant='outlined'
                            label='Image Set Selection'
                            placeholder='Select an image set...'
                            value={imageSelection}
                            onChange={e => setImageSelection(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                                  <Icon color='disabled' fontSize='small'>
                                    burst_mode
                                  </Icon>
                                </InputAdornment>
                              ),
                            }}
                          >
                            <MenuItem value='pickup'>Pickup Images - {loanerPickupName}</MenuItem>
                            <MenuItem value='delivery'>Delivery Images - {loanerDeliveryName}</MenuItem>
                          </TextField>

                          <ImageCarousel
                            size='lg'
                            images={imageSelection === `pickup` ? loanerPickupPhotos : loanerDeliveryPhotos}
                          />
                        </div>
                      </>
                    ) : null}

                    {(conciergePickupPhotos && conciergePickupPhotos.length) ||
                    (conciergeDeliveryPhotos && conciergeDeliveryPhotos.length) ? (
                      <>
                        <Divide spacer tip={`View your vehicle's images from the pickup and delivery.`}>
                          Your Vehicle Images
                        </Divide>
                        <div className={cls.paper}>
                          <TextField
                            select
                            fullWidth
                            size='small'
                            variant='outlined'
                            label='Image Set Selection'
                            placeholder='Select an image set...'
                            value={imageSelection}
                            onChange={e => setImageSelection(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                                  <Icon color='disabled' fontSize='small'>
                                    burst_mode
                                  </Icon>
                                </InputAdornment>
                              ),
                            }}
                          >
                            <MenuItem value='pickup'>Pickup Images - {conciergePickupName}</MenuItem>
                            <MenuItem value='delivery'>Delivery Images - {conciergeDeliveryName}</MenuItem>
                          </TextField>

                          <div className={cls.break_sm} />

                          <ImageCarousel
                            size='lg'
                            images={imageSelection === `pickup` ? conciergePickupPhotos : conciergeDeliveryPhotos}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>NO CONCIERGE RECORD FOUND</Typography>
                  </div>
                );
            }}
          </Subscription>
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headL: {
    verticalAlign: 'top',
    lineHeight: 1,
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  headR: {
    verticalAlign: 'top',
    lineHeight: 1.333,
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  phone: {
    fontWeight: 600,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  notFound: {
    padding: theme.spacing(4),
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

////////// GRAPHQL //////////
const GET_CONCIERGE_MOVE = gql`
  subscription {
    moves(
      # where: {
      #   _or: [
      #     { consumer_pickup: { _eq: true } }
      #     { _and: [{ consumer_type: { _eq: "customer" } }, { consumer_pickup: { _eq: false } }] }
      #   ]
      # }
      order_by: { sequence: asc_nulls_last }
    ) {
      id
      cancel_status
      config
      consumer_at_pickup
      consumer_name
      consumer_phone
      consumer_pickup
      consumer_type
      delivery_arrived
      delivery_started
      delivery_stop_id
      delivery_successful
      delivery_time
      delivery_workflow_data
      driver_app_version
      driver_id
      driver_name
      move_type
      pickup_arrived
      pickup_started
      pickup_stop_id
      pickup_successful
      pickup_time
      pickup_workflow_data
      sequence
      status
      tracking_link
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_year
      driver {
        id
        user {
          id
          avatar_url
          display_name
        }
      }
      pickup_photos: vehiclephotos(where: { workflow: { type: { _eq: "pickup" } }, status: { _eq: "done" } }) {
        id
        step_id
        name
        url
        location
      }
      delivery_photos: vehiclephotos(where: { workflow: { type: { _eq: "delivery" } }, status: { _eq: "done" } }) {
        id
        step_id
        name
        url
        location
      }
      customer {
        config
        id
        name
        branded
      }
      lane {
        id
        distance_miles
        pickup {
          id
          name
          address
          latitude
          longitude
        }
        delivery {
          id
          name
          address
          latitude
          longitude
        }
      }
      lyftride {
        activeAttempt {
          id
          driver_vehicle_color
          driver_vehicle_img_url
          driver_vehicle_license_plate
          driver_vehicle_license_plate_state
          driver_vehicle_make
          driver_vehicle_model
          driver_vehicle_year
          estimated_arrival_time
          status
        }
      }
      childMoves {
        id
        cancel_status
        consumer_type
        delivery_arrived
        delivery_stop_id
        delivery_time
        move_type
        pickup_arrived
        pickup_stop_id
        pickup_time
        sequence
        status
        tags
        tracking_link
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_year
      }
      moveByReturnRideId {
        id
        cancel_status
        delivery_arrived
        delivery_stop_id
        delivery_time
        pickup_arrived
        pickup_stop_id
        pickup_time
        sequence
        status
        tags
        tracking_link
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_year
      }
      parent_move {
        id
        cancel_status
        consumer_at_pickup
        consumer_type
        delivery_arrived
        delivery_stop_id
        delivery_time
        move_type
        pickup_arrived
        pickup_stop_id
        pickup_time
        sequence
        status
        tags
        tracking_link
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_year
      }
      parentMove {
        id
        cancel_status
        consumer_at_pickup
        consumer_type
        delivery_arrived
        delivery_stop_id
        delivery_time
        pickup_arrived
        pickup_stop_id
        pickup_time
        sequence
        status
        tags
        tracking_link
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_year
        delivery_photos: vehiclephotos(where: { workflow: { type: { _eq: "delivery" } } }) {
          id
          step_id
          name
          url
          location
        }
        pickup_photos: vehiclephotos(where: { workflow: { type: { _eq: "pickup" } } }) {
          id
          step_id
          name
          url
          location
        }
        pickup_workflow_data
        delivery_workflow_data
        workflowset {
          id
          pickupWorkflow {
            id
            steps
          }
          deliveryWorkflow {
            id
            steps
          }
          fuelWorkflow {
            id
            steps
          }
        }
      }
    }
  }
`;
