import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import dayjs from 'dayjs';
////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  title: {
    marginBottom: '2px',
    display: 'flex',
    alignItems: 'baseline',
    color: theme.palette.primary.main,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 600,
  },
  locationText: {
    fontSize: '14px', // Adjust font size for feature list
    fontWeight: 400,
  },
  dotContainer: {
    display: 'flex',
    marginLeft: '12px',
    gap: '3px',
    padding: '4px 0',
  },
  dot: {
    width: '2px',
    height: '2px',
    backgroundColor: theme.palette.grey[400],
    borderRadius: '50%',
  },
  addressContainer: {
    position: 'relative',
    paddingLeft: '25px',  // Space for the icon
  },
  icon: {
    position: 'absolute',
    left: 0,
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  connector: {
    position: 'absolute',
    left: '9px',  // Centers with icons
    top: '24px',  // Position between addresses
    width: '2px',
    height: '20px',
    background: `linear-gradient(${theme.palette.grey[400]} 33%, transparent 0%)`,
    backgroundSize: '2px 6px',
    marginBottom: '2px',
    marginTop: '-2px'
  }
}));

export default function AppointmentInfo({ appointment }) {
  const cls = useStyles();

  const getFormattedAppointmentTime = appointmentTime => {
    return dayjs(appointmentTime).format('MM/DD/YYYY, h:mm a');
  };

  const getFormattedAddress = address => {
    const parts = address.split(',').map(part => part.trim());
    const state = parts[2] ? parts[2].split(' ')[0] : '';
    return `${parts.slice(0, 2).join(', ')}, ${state}`;
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <Typography className={cls.title}>Appointment Details</Typography>
          <Typography className={cls.tip}>
            {appointment.consumer_at_pickup 
              ? 'At the date and time below, a driver will meet you at your location to deliver your vehicle to the service center.' 
              : 'At the date and time below, a driver will pick up your vehicle at the service center to deliver it to your location.'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' component='div' style={{fontWeight: 'bold', marginBottom: '8px'}}>
            {getFormattedAppointmentTime(appointment.appointment_time)}
          </Typography>
          <div className={cls.addressContainer}>
            {appointment.consumer_at_pickup ? 
              <PersonPinCircleIcon className={cls.icon} /> : 
              <LocationCityIcon className={cls.icon} />
            }
            <Typography className={cls.locationText}>
              {getFormattedAddress(appointment.lane.pickup.address)}
            </Typography>
            <div className={cls.connector} />
            {!appointment.consumer_at_pickup ? 
              <PersonPinCircleIcon className={cls.icon} style={{ top: '44px' }} /> : 
              <LocationCityIcon className={cls.icon} style={{ top: '44px' }} />
            }
            <Typography className={cls.locationText} style={{ marginTop: '24px' }}>
              {getFormattedAddress(appointment.lane.delivery.address)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
