import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  CircularProgress,
} from '@material-ui/core';
import { GlobalContext } from '../../global-context';
import CheckIcon from '@material-ui/icons/Check'; // Import the simpler CheckIcon

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  block: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '56px',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  btn: {
    minHeight: '56px',
  },
  btnActive: {
    minHeight: '56px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  priceTitle: {
    marginBottom: '2px',
    display: 'flex',
    alignItems: 'baseline',
    color: theme.palette.primary.main,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 600,
  },
  featureText: {
    fontSize: '12px', // Adjust font size for feature list
    fontWeight: 400,
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: '16px',
  },
}));

export default function Pricing({ amount, distanceMiles }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [price, setPrice] = useState('$');

  useEffect(() => {
    setPrice(`$${amount.toFixed(2)}`);
  }, [amount]);

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <Typography className={cls.priceTitle}>Drive Service</Typography>
          <Typography className={cls.tip}>This price includes our comprehensive vehicle transport service.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h5'
            component='div'
            style={{
              fontWeight: 'bold',
              marginBottom: '8px',
              display: 'flex',
              alignItems: 'baseline',
              minWidth: '20px',
            }}
          >
            {amount <= 0 && (
              <CircularProgress
                size='1rem'
                style={{
                  marginLeft: '0px',
                  color: '#f44336',
                }}
              />
            )}
            {amount > 0 && price}
            <Typography variant='subtitle1' component='span' style={{ fontWeight: 'normal', marginLeft: '4px' }}>
              / one way
            </Typography>
          </Typography>
          <Typography variant='caption' color='textPrimary' style={{ marginBottom: '4px', display: 'block' }}>
            Drive distance: <strong>{distanceMiles} miles</strong>
          </Typography>
          <List>
            {[
              'Pickup of your vehicle',
              'Realtime GPS tracking',
              'Pickup inspection with photos',
              'Realtime status update text messages',
              'Delivery of your vehicle with photos',
            ].map((feature, index) => (
              <ListItem key={index} style={{ padding: '0px 0' }}>
                <ListItemIcon style={{ minWidth: '25px' }}>
                  <CheckIcon className={cls.checkIcon} />
                </ListItemIcon>
                <ListItemText primary={<span className={cls.featureText}>{feature}</span>} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
