import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  paidContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e8f5e9',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
    border: '1px solid #a5d6a7',
  },
  checkIcon: {
    color: '#43a047',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  successText: {
    color: '#2e7d32',
    fontWeight: 500,
  },
  failedContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffebee',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
    border: '1px solid #ef9a9a',
  },
  errorIcon: {
    color: '#d32f2f',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  errorText: {
    color: '#c62828',
    fontWeight: 500,
  },
  canceledContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
    border: '1px solid #e0e0e0',
  },
  cancelIcon: {
    color: '#757575',
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
  canceledText: {
    color: '#616161',
    fontWeight: 500,
  },
}));

export default function Welcome({ name, status, customer, expired, apptTime }) {
  const cls = useStyles();

  const [paymentDeadline, setPaymentDeadline] = React.useState(null);

  const getPaymentDeadline = (apptTime) => {
    const deadline = dayjs(apptTime).subtract(90, 'minute').format('MM/DD/YYYY, hh:mm A');
    setPaymentDeadline(deadline);
  };

  React.useEffect(() => {
    if (apptTime) {
      getPaymentDeadline(apptTime);
    }
  }, [apptTime]);

  return (
    <Grid container className={cls.container} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' className={cls.title}>
          Hi, {name}!
        </Typography>
        {status === 'paid' ? (
          //green box showing payment successful
          <>
            <div className={cls.paidContainer}>
              <CheckCircleIcon className={cls.checkIcon} />
              <Typography variant='body1' className={cls.successText}>
                Payment successful!
              </Typography>
            </div>
            <Typography variant='body2' className={cls.description}>
              Your payment has been processed successfully and your appointment is confirmed. On the day of your
              appointment, you will receive a text message introducing your driver when they are on the way to your
              vehicle.
              {customer.phone && customer.name
                ? `If you have any questions, please contact ${customer.name} at ${customer.phone}.`
                : `If you have any questions, please contact your service center.`}
            </Typography>
          </>
        ) : status === 'failed' && !expired ? (
          //red box showing payment failed
          <>
            <div className={cls.failedContainer}>
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <ErrorIcon className={cls.errorIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant='body1' className={cls.errorText}>
                    Payment failed!
                  </Typography>
                  <Typography variant='body2' className={cls.description} style={{ marginTop: '8px' }}>
                    Something went wrong processing your payment. Please try again below.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </>
        ) : status === 'canceled' ? (
          <>
            <div className={cls.canceledContainer}>
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <ErrorIcon className={cls.cancelIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant='body1' className={cls.canceledText}>
                    Appointment canceled!
                  </Typography>
                  <Typography variant='body2' className={cls.description} style={{ marginTop: '8px' }}>
                    This appointment has been canceled. Please contact your vehicle service center to reschedule.
                    {customer.phone && customer.name
                      ? `If you have any questions, please contact ${customer.name} at ${customer.phone}.`
                      : `If you have any questions, please contact your service center.`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </>
        ) : expired ? (
          <>
            <div className={cls.failedContainer}>
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <ErrorIcon className={cls.failedIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant='body1' className={cls.failedText}>
                    Offer expired!
                  </Typography>
                  <Typography variant='body2' className={cls.description} style={{ marginTop: '8px' }}>
                    To ensure time for scheduling, payment must be received within 90 minutes of the appointment.
                    {customer.phone && customer.name
                      ? `If you would like to update your appointment time, please contact ${customer.name} at ${customer.phone}.`
                      : `If you would like to update your appointment time, please contact your service center.`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <Typography variant='body2' className={cls.description}>
            We're excited to pick up your vehicle for you. Please review the information below and proceed to payment
            details.

            {paymentDeadline && (
              <Typography variant='body2' className={cls.description}>
                To ensure time for scheduling, please pay by <strong>{paymentDeadline}</strong>.
              </Typography>
            )}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
