import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Pricing from './Pricing';
import Payment from './Payment';
import Welcome from './Welcome';
import VehicleInfo from './VehicleInfo';
import AppointmentInfo from './AppointmentInfo';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { GlobalContext } from '../../global-context';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paperWhite: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  paperGray: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: '18px',
    },
  },
  sub: {
    maxWidth: '640px',
    marginBottom: '-16px',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-12px',
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-8px',
      fontSize: '12px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  block: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '56px',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    marginTop: theme.spacing(2),
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

export default function AppointmentContent({ appointment }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [amount, setAmount] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [dpmCheckerLink, setDpmCheckerLink] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [paymentProcessed, setPaymentProcessed] = useState(false);
  const [expired, setExpired] = React.useState(false);

  const getExpiration = (apptTime) => {
    const now = new Date();
    const appointmentTime = new Date(apptTime);
    const timeDifference = (appointmentTime - now) / (1000 * 60); // difference in minutes

    if (timeDifference < 90) {
      setExpired(true);
    } else {
      setExpired(false);
    }
  };

  const getRate = async ({ distance_miles, rateClass }) => {
    try {
      const response = await axios.post(
        '/.netlify/functions/getRate',
        { distance_miles, rateClass },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ctx.token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching rate:', error);
      return 0;
    }
  };

  const getPaymentIntent = async ({ amount, appointment_id }) => {
    // TODO: Fetch payment intent from Stripe is a payment_intent_id is saved on the appointment
    try {
      const response = await axios.post(
        '/.netlify/functions/createPaymentIntent',
        {
          amount,
          appointment_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ctx.token}`,
          },
        }
      );

      const { clientSecret, dpmCheckerLink, paymentIntentId } = response.data;

      setPaymentIntentId(paymentIntentId);
      setClientSecret(clientSecret);
      setDpmCheckerLink(dpmCheckerLink);
    } catch (error) {
      console.error('Error fetching payment intent:', error);
      setPaymentIntentId(null);
      setClientSecret(null);
      setDpmCheckerLink(null);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (appointment && appointment.id && appointment.lane && appointment.lane.distance_miles) {
        const { amount } = await getRate({
          appointment_id: appointment.id,
          distance_miles: appointment.lane.distance_miles,
          rateClass: 'stranded',
        });
        setAmount(amount);
      }
      if (appointment.status === 'paid') {
        setPaymentProcessed(true);
        return;
      }

      // Check URL for redirect FIRST
      const redirectStatus = new URLSearchParams(window.location.search).get('redirect_status');
      const paymentIntentIdFromURL = new URLSearchParams(window.location.search).get('payment_intent');
      
      if (redirectStatus === 'succeeded' && paymentIntentIdFromURL) {
        console.log('Successful payment redirect detected, updating appointment status');
        setPaymentIntentId(paymentIntentIdFromURL);
        setPaymentProcessed(true);
        return;
      }

      // If the appointment has a failed payment, get that payment intent
      if (appointment.status === 'failed' && appointment.payment_transaction_id) {
        const response = await axios.post(
          '/.netlify/functions/createPaymentIntent',
          {
            appointment_id: appointment.id,
            payment_intent_id: appointment.payment_transaction_id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${ctx.token}`,
            },
          }
        );
        
        setPaymentIntentId(response.data.paymentIntentId);
        setClientSecret(response.data.clientSecret);
        return;
      }
    };

    if (appointment && appointment.appointment_time && appointment.status && appointment.status !== 'paid' && appointment.status !== 'canceled') {
      getExpiration(appointment.appointment_time);
    }

    init();
  }, [appointment]);

  useEffect(() => {
    if (paymentProcessed || paymentIntentId || !amount) return;

    const fetchPaymentIntent = async () => {
      await getPaymentIntent({ amount, appointment_id: appointment.id });
    };

    fetchPaymentIntent();
  }, [amount, paymentProcessed, paymentIntentId]);

  const appearance = {
    theme: 'stripe',
  };

  const loader = 'auto';

  const handleBeginPayment = async error => {
    let failureReason = error ? error.message : null;
    try {
      const response = await axios.post(
        '/.netlify/functions/updateAppointmentStatus',
        { id: appointment.id, status: 'paying', payment_intent_id: paymentIntentId, failure_reason: failureReason },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ctx.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating appointment status:', error);
    }
  };

  return (
    <div key={`appointment-${appointment.id}`} style={{ display: 'block' }}>
      <Welcome name={appointment.consumer_name} status={appointment.status} customer={appointment.customer} expired={expired} apptTime={appointment.appointment_time} />

      <Grid item md={12} xs={12}>
        <div className={cls.paperWhite}>
          <Pricing amount={amount / 100} distanceMiles={appointment.lane.distance_miles} />
        </div>
      </Grid>

      <div className={cls.break} />

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <div className={cls.paperWhite}>
            <AppointmentInfo appointment={appointment} />
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className={cls.paperGray}>
            <VehicleInfo
              appointment={appointment}
              refNumLabel={appointment.customer.organization.config.appointments.ref_num_label}
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          {clientSecret &&  appointment.status !== 'paid' && appointment.status !== 'canceled' && expired === false ? (
                <div className={cls.paperWhite}>
                  <Elements options={{ clientSecret, appearance, loader }} stripe={stripePromise}>
                    <Payment
                      setAppointmentToPaying={handleBeginPayment}
                      paymentFailureReason={appointment?.payment_failure_reason}
                      dpmCheckerLink={dpmCheckerLink}
                      appointmentId={appointment.id}
                      clientSecret={clientSecret}
                    />
                  </Elements>
                </div>
              ) : (
                <></>
              )}
        </Grid>
      </Grid>
    </div>
  );
}
