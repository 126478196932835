import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Container, Button, Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Subscription } from 'react-apollo';
import Loading from '../utils/Loading';
import gql from 'graphql-tag';
import { GlobalContext } from '../global-context';
import AppointmentContent from './appointmentDetails/AppointmentContent';

const GET_APPOINTMENT = gql`
  subscription concierge_getAppointment($id: bigint!) {
    appointments_by_pk(id: $id) {
      id
      consumer_name
      consumer_phone
      accepted_terms
      appointment_time
      consumer_at_pickup
      config
      createdat
      createdby
      driver_notes
      payment_failure_reason
      status
      updatedat
      updatedby
      payment_transaction_id
      offered_by
      offered_at
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      vehicle_manual
      vehicle_vin
      move_id
      customer_id
      lane {
        id
        description
        duration_sec
        distance_miles
        pickup {
          id
          address
          name
        }
        delivery {
          id
          address
          name
        }
      }
      customer {
        id
        branded
        name
        phone
        organization {
          id
          name
          config
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paperWhite: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  paperGray: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: '18px',
    },
  },
  sub: {
    maxWidth: '640px',
    marginBottom: '-16px',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-12px',
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-8px',
      fontSize: '12px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  block: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '56px',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    marginTop: theme.spacing(2),
  },
}));

export default function AppointmentDetails({ appointmentId }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <Subscription
          subscription={GET_APPOINTMENT}
          variables={{ id: appointmentId }}
          onError={err => console.error('Failed to retrieve appointment:', err)}
        >
          {result => {
            if (result.loading) return <Loading fixed />;
            if (result.error) {
              return (
                <Alert severity='error' className={cls.errorMessage}>
                  Something went wrong while retrieving your appointment details. {result.error.message}
                </Alert>
              );
            }

            const { data } = result;
            const appointment = data && data.appointments_by_pk ? data.appointments_by_pk : {};

            if (!appointment || !appointment.id) {
              return <Alert severity='warning'>NO APPOINTMENT RECORD FOUND</Alert>;
            }

            return <AppointmentContent appointment={appointment} />;
          }}
        </Subscription>
      </Container>
    </div>
  );
}
