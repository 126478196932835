import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyText: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '15px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  title: {
    marginBottom: '2px',
    display: 'flex',
    alignItems: 'baseline',
    color: theme.palette.primary.main,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 600,
  },
  dotContainer: {
    display: 'flex',
    marginLeft: '12px',
    gap: '3px',
    padding: '4px 0',
  },
  dot: {
    width: '2px',
    height: '2px',
    backgroundColor: theme.palette.grey[400],
    borderRadius: '50%',
  },
  icon: {
    position: 'absolute',
    left: 0,
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  infoText: {
    fontSize: '15px',
    fontWeight: 600,
    marginBottom: '8px',
  },
}));

/** Convert the first letter of each word to lowercase */
const lowerFirstLetterOfEachWord = (str = '') => {
  return str.replace(/\b\w/g, char => char.toLowerCase());
};

export default function VehicleInfo({ appointment, refNumLabel }) {
  const cls = useStyles();

  /** Capitalize the first letter of the first word */
const capFirst = (str = null) => {
    if (str && typeof str === `string`) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

/** Get formatted vehicle string from the move object */
const getFormattedVehicleFromAppointment = (appointment = null, includeYear = true, includeColor = true) => {
    // Default fallback vehicle string when not enough info is provided
    const fallbackVehicle = `No Vehicle Info`;
  
    // Check for move
    if (appointment) {
      // Set local variables
      const year = appointment.vehicle_year || null;
      const make = appointment.vehicle_make || null;
      const model = appointment.vehicle_model || null;
      const color = appointment.vehicle_color || null;
  
      // Set local vehicle string
      let vehicle = ``;
  
      // Check for make and model
      if (make && model) vehicle = `${make} ${model}`;
      else if (make) vehicle = make;
      else if (model) vehicle = model;
      else vehicle = fallbackVehicle;
  
      // Include color and year if specified
      if (includeYear && year) vehicle = `${year} ${vehicle}`;
      if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;
  
      // Return the built vehicle string
      return vehicle;
    }
    return fallbackVehicle;
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <Typography className={cls.title}>Additional Info</Typography>
          <Typography className={cls.tip}>
            {`Vehicle information, driver instructions, and the ${
              refNumLabel ? lowerFirstLetterOfEachWord(refNumLabel) : 'reference number'
            } provided by the service center to reference your appointment.`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' className={cls.keyText}>
            {refNumLabel ? `${capFirst(refNumLabel)}: ` : 'Reference #: '}
          </Typography>
          <Typography variant='body2' className={cls.infoText}>
            {appointment.config.claim_number ? appointment.config.claim_number : 'N/A'}
          </Typography>
          <Typography variant='body2' className={cls.keyText}>
            Vehicle:
          </Typography>
          <Typography variant='body2' className={cls.infoText}>
            {getFormattedVehicleFromAppointment(appointment)}
          </Typography>
          <Typography variant='body2' className={cls.keyText}>
            VIN:
          </Typography>
          <Typography variant='body2' className={cls.infoText}>
            {appointment.vehicle_vin ? appointment.vehicle_vin : 'N/A'} 
          </Typography>
          <Typography variant='body2' className={cls.keyText}>
            Driver Instructions:
          </Typography>
          <Typography variant='body2' className={cls.infoText}>
            {appointment.driver_notes ? appointment.driver_notes : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}